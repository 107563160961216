<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="error"-->
<!--                effect="dark" style="margin-bottom: 20px" >-->
<!--            </el-alert>-->
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/nvshen/001.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/nvshen/002.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/nvshen/003.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/nvshen/004.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[20].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[20].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[21].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[21].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[22].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[22].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[23].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[23].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/nvshen/005.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[24].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[24].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[25].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[25].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/nvshen/006.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[26].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[26].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[27].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[27].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "nvshen",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/nvshen/1034487.png','id':'1034487','search':''},      //16784	1034487	西洋参
        {'index':1,'url':'http://image.yabyy.com/home/nvshen/1009785.png','id':'1009785','search':''},       //10881	1009785	燕窝 白燕盏
        {'index':2,'url':'http://image.yabyy.com/home/nvshen/1013606.png','id':'1013606','search':''},     //12872	1013606	川贝母
        {'index':3,'url':'http://image.yabyy.com/home/nvshen/1013873.png','id':'1013873','search':''},      //12974	1013873	红参
        {'index':4,'url':'http://image.yabyy.com/home/nvshen/1035225.png','id':'1035225','search':''},     //17073	1035225	铁皮石斛
        {'index':5,'url':'http://image.yabyy.com/home/nvshen/1038089.png','id':'1038089','search':''},      //18686	1038089	冬虫夏草(礼盒)
        {'index':6,'url':'http://image.yabyy.com/home/nvshen/1038092.png','id':'1038092','search':''},      //19440	1038092	海参(60头)(礼盒)
        {'index':7,'url':'http://image.yabyy.com/home/nvshen/1035221.png','id':'1035221','search':''},      //16938	1035221	陈皮(15年份)

        {'index':8,'url':'http://image.yabyy.com/home/nvshen/1009780.png','id':'1009780','search':''},      //10876	1009780	燕窝(白燕盏)
        {'index':9,'url':'http://image.yabyy.com/home/nvshen/1015199.png','id':'1015199','search':''},      //13685	1015199	燕窝
        {'index':10,'url':'http://image.yabyy.com/home/nvshen/1009783.png','id':'1009783','search':''},      //10879	1009783	燕窝)(白燕盏)
        {'index':11,'url':'http://image.yabyy.com/home/nvshen/1009784.png','id':'1009784','search':''},      //10880	1009784	燕窝(白燕盏)

        {'index':12,'url':'http://image.yabyy.com/home/nvshen/1035217.png','id':'1035217','search':''},      //16935	1035217	西洋参(罐装)
        {'index':13,'url':'http://image.yabyy.com/home/nvshen/1035218.png','id':'1035218','search':''},      //16936	1035218	西洋参
        {'index':14,'url':'http://image.yabyy.com/home/nvshen/1009851.png','id':'1009851','search':''},      //10922	1009851	西洋参
        {'index':15,'url':'http://image.yabyy.com/home/nvshen/1041481.png','id':'1041481','search':''},      //20785	1041481	西洋参

        {'index':16,'url':'http://image.yabyy.com/home/nvshen/1035220.png','id':'1035220','search':''},      //16937	1035220	陈皮(10年份)
        {'index':17,'url':'http://image.yabyy.com/home/nvshen/1035222.png','id':'1035222','search':''},      //16939	1035222	陈皮
        {'index':18,'url':'http://image.yabyy.com/home/nvshen/1039651.png','id':'1039651','search':''},      //19484	1039651	陈皮(广陈皮)
        {'index':19,'url':'http://image.yabyy.com/home/nvshen/1039649.png','id':'1039649','search':''},      //19482	1039649	陈皮(广陈皮)
        {'index':20,'url':'http://image.yabyy.com/home/nvshen/1039650.png','id':'1039650','search':''},      //19483	1039650	陈皮(广陈皮)
        {'index':21,'url':'http://image.yabyy.com/home/nvshen/1041479.png','id':'1041479','search':''},      //20783	1041479	陈皮(广陈皮)(15年份)(礼盒装)
        {'index':22,'url':'http://image.yabyy.com/home/nvshen/1041480.png','id':'1041480','search':''},      //20784	1041480	陈皮(广陈皮)(10年份)(礼盒装)
        {'index':23,'url':'http://image.yabyy.com/home/nvshen/1041478.png','id':'1041478','search':''},      //20782	1041478	陈皮(广陈皮)(5年份)(礼盒装)

        {'index':24,'url':'http://image.yabyy.com/home/nvshen/1014589.png','id':'1014589','search':''},      //13357	1014589	枸杞子
        {'index':25,'url':'http://image.yabyy.com/home/nvshen/1014588.png','id':'1014588','search':''},      //13356	1014588	枸杞子

        {'index':26,'url':'http://image.yabyy.com/home/nvshen/1035340.png','id':'1035340','search':''},      //16942	1035340	金银花
        {'index':27,'url':'http://image.yabyy.com/home/nvshen/1035443.png','id':'1035443','search':''},      //17334	1035443	金银花
      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
